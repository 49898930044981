

<!-- Footer -->
<footer class="page-footer font-small unique-color-dark footerbg">

  
    <!-- Footer Links -->
    <div class="container text-center text-md-left mt-5 footerbg">
  
      <!-- Grid row -->
      <div class="row mt-3">
  
        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
  
          <!-- Content -->
          <a class="navbar-brand" href="#"><img src="assets/img/logo.png" width="200"></a>
          <p class="footerAbout">NIMS charity works in communities around the world. They are committed to being there until local people no longer need their help. What's more, local people decide which projects will give them the best chance of changing their lives for the better.
          </p>

      <div class="col col-lg-2 socialmedia">
        <a class="socialicons" href = "https://www.facebook.com/NIMSMedicity/"><i class="fa fa-facebook 	" aria-hidden="true"></i></a>
        <a class="socialicons" href = #><i class="fa fa-instagram " aria-hidden="true"></i></a>
        <a class="socialicons" href = #><i class="fa fa-twitter " aria-hidden="true"></i></a>
        <a class="socialicons" href = #><i class="fa fa-google " aria-hidden="true"></i></a>
      </div>

  
        </div>
        <!-- Grid column -->
  
        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 d-none d-md-block">
  
          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Latest News</h6>
          <hr style="width:25%;text-align:left;margin-left:0">
          <div class="row">
            <div class="col-5">
              <img src="assets/img/Pastevents/23-01-2020.jpg" width="80px" height="55" alt="..." class="">
            </div>
            <div class="col-7">
              <p class="desc">Medical Camp at Kaliyakkavilai</p>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <img src="assets/img/Pastevents/19-01-2020.jpg" width="80px" height="55" alt="..." class="">
            </div>
            <div class="col-7">
              <p class="desc">Medical Camp at Kanjiramkulam</p>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <img src="assets/img/Pastevents/23-01-2020.jpg" width="80px" height="55" alt="..." class="">
            </div>
            <div class="col-7">
              <p class="desc">Medical Camp at Nanniyode</p>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <img src="assets/img/Pastevents/25-01-2020.jpg" width="80px" height="55" alt="..." class="">
            </div>
            <div class="col-7">
              <p class="desc">Medical Camp at Panayamuttom</p>
            </div>
          </div>
  
        </div>
        <!-- Grid column -->
  
        <!-- Grid column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
  
          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Useful links</h6>
          <hr class="d-none d-md-block" style="width:25%;text-align:left;margin-left:0">
          <p>
            <a class="footerlink" href="/charity/spectrum-child-development-research-center">Spectrum</a>
          </p>
          <p>
            <a class="footerlink" href="/charity">Charity Services</a>
          </p>
          <p>
            <a class="footerlink" href="/about-us">Dream of Us</a>
          </p>
          <p>
            <a class="footerlink" href="/charity">NIMS Services</a>
          </p>
          <p>
            <a class="footerlink" href="/charity/heart-to-heart">Free Heart Surgery</a>
          </p>
  
        </div>
        <!-- Grid column -->
  
        <!-- Grid column -->
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
  
          <!-- Links -->
          <h6  class="text-uppercase font-weight-bold">Contact</h6>
          <hr class="d-none d-md-block" style="width:18%;text-align:left;margin-left:0">
          <p>
            <i class="fas fa-home mr-3"></i>NICE GARDEN,<br>
            <span class="conpadd">ARALUMMOODU,</span><br>
            <span class="conpadd"> NEYYATTINKARA,</span><br> <span class="conpadd">THIRUVANANTHAPURAM</span></p>
          <p>
            <i class="fas fa-envelope mr-3"></i> nimscharity@gmail.com</p>
          <p>
            <i class="fas fa-phone mr-3"></i> +91 9388243399</p>
          <p>
            <i class="fas fa-phone mr-3"></i>  0471 - 2226513</p>
  
        </div>
        <!-- Grid column -->
  
      </div>
      <!-- Grid row -->
  
    </div>
    <!-- Footer Links -->
  
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2021 Copyright: <a class="footerlink2" href="https://aptnotions.com/">NIMS Hospital</a>.
      Developed and Managed by<a class="footerlink2" href="https://aptnotions.com/"> Aptnotions Infotech</a>
    </div>
    <!-- Copyright -->
  
  </footer>
  <!-- Footer -->


  