import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spectrum-slider',
  templateUrl: './spectrum-slider.component.html',
  styleUrls: ['./spectrum-slider.component.css']
})
export class SpectrumSliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
