import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dream-us',
  templateUrl: './dream-us.component.html',
  styleUrls: ['./dream-us.component.css']
})
export class DreamUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
