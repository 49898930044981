<div class="container-fluid actionbar">
  <div class="container actionbarstyle">
    <div class="row">
      <div class="col">
        <marquee
          >SUPPORT US WITH YOUR BLESSINGS, NOT WITH MONEY.
          For any assistance call us now.</marquee
        >
      </div>
      <div class="col-md-auto">
        <i class="fa fa-phone fa-flip-horizontal" aria-hidden="true"></i
        >&nbsp;&nbsp;<a style="color: white;" href="tel:+91 9388243399">+91 9388243399</a>, <a style="color: white;" href="tel:0471 2226513">0471 2226513</a>
      </div>    
      <div class="col-md-auto">
        <i class="fa fa-envelope fa-flip-horizontal" aria-hidden="true"></i
        >&nbsp;&nbsp;nimscharity@gmail.com
      </div>
      <div class="col col-lg-2 socialmedia">
        <a class="socialicons" href="https://www.facebook.com/NIMSMedicity/"
          ><i class="fa fa-facebook" aria-hidden="true"></i
        ></a>
        <a class="socialicons" href="#"
          ><i class="fa fa-instagram" aria-hidden="true"></i
        ></a>
        <a class="socialicons" href="#"
          ><i class="fa fa-twitter" aria-hidden="true"></i
        ></a>
        <a class="socialicons" href="https://www.youtube.com/channel/UCCUQcIG55Bb64k0X_hlvOLA"
          ><i class="fa fa-youtube" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</div>

<!-- Main Manu -->
<div class="container">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#"
      ><img src="assets/img/logo.png" width="200"
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="nav navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link active" href="#">HOME</a>
        </li>
        <li class="nav-item dropdown">
          <a routerLink="/charity"  class="nav-link dropdown-toggle"  data-toggle="dropdown"
            >CHARITY SERVICES</a
          >
          <ul class="dropdown-menu">
            <li
              class="submenustyle"
              *ngFor="let charityLabel of homeButtonLabels"
            >
              <a
                class="dropdown-item"
                routerLink="{{ charityLabel?.routingUrl }}"
                >{{ charityLabel?.buttonLabel }}</a
              >
            </li>
          </ul>
        </li>
        
        <li class="nav-item dropdown">
          
          <a routerLink="/events"  class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"
            >EVENTS</a
          >
          <button class="css-selector" type="button">New</button>
          <ul class="dropdown-menu">
            <li
              class="submenustyle"
              *ngFor="let eventsLabel of eventsButtonLabels"
            >
              <a
                class="dropdown-item"
                routerLink="{{eventsLabel?.routingUrl}}"
                >{{eventsLabel?.buttonLabel}}</a
              >
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/about-us">ABOUT US</a>
        </li>
        <li class="nav-item dropdown">
          <a routerLink="/contact-us" class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"
            >CONTACT US</a
          >
          <ul class="dropdown-menu" style="list-style-type: none;">
            <li class="submenustyle">
              <a class="dropdown-item" href="https://www.nimshospital.com/">NIMS HOSPITAL</a>
            </li>
            <li class="submenustyle">
              <a class="dropdown-item" href="http://www.niuniv.com/">NOORUL ISLAM UNIVERSITY</a>
            </li>
          </ul>
          <!-- <ul class="dropdown-menu">
            <li
              class="submenustyle"
              *ngFor="let contactLabel of contactButtonLabels"
            >
              <a 
                class="dropdown-item"
                routerLink="{{contactLabel?.routingUrl}}"
                >{{contactLabel?.buttonLabel}}
              </a>
            </li>
          </ul> -->
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">NIMS SERVICES</a>
        </li> -->
      </ul>
    </div>
  </nav>
</div>


