import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-civil-slider',
  templateUrl: './civil-slider.component.html',
  styleUrls: ['./civil-slider.component.css']
})
export class CivilSliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
