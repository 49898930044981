<!-- <div
  id="carousel-example-2"
  class="carousel slide carousel-fade z-depth-1-half"
  data-ride="carousel">
  <div class="carousel-inner" role="listbox">
    <div class="carousel-item active">
      <div class="view">
        <img
          class="d-block w-100"
          src="assets/img/slider/slider2.jpg"
          alt="First slide"
        />
        <div class="mask rgba-black-light"></div>
      </div>

    </div>
  </div>

  <a
    class="carousel-control-prev"
    href="#carousel-example-2"
    role="button"
    data-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next"
    href="#carousel-example-2"
    role="button"
    data-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
 -->

<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img
        class="d-block w-100"
        src="assets/img/slider/NICHE-STEVEJOBS.jpeg"
        alt="First slide"
      />
    </div>
    <div class="carousel-item">
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="assets/img/slider/hridhayam banner1.jpg"
          alt="First slide"
        />
      </div>
    <div>
      <img
        class="d-block w-100"
        src="assets/img/slider/hridhayam banner2.jpg"
        alt="First slide"
      />
    </div>
    <div class="carousel-item">
      <img
        class="d-block w-100"
        src="assets/img/slider/hridhayam banner.jpg"
        alt="First slide"
      />
    </div>
    <div class="carousel-item">
      <img
        class="d-block w-100"
        src="assets/img/slider/slider2.jpg"
        alt="First slide"
      />
      <div class="carousel-caption caption">
        <h3 class="slidertext slide-in-left">
          <b
            ><i
              >"SUPPORT US WITH YOUR BLESSINGS <br />
              NOT WITH MONEY"</i
            ></b
          >
        </h3>
      </div>
    </div>
    <div class="carousel-item">
      <img
        class="d-block w-100"
        src="assets/img/slider/slider4.jpg"
        alt="First slide"
      />
      <div class="carousel-caption caption">
        <h3 class="slidertext slide-in-left">
          <b
            ><i
              >NIMS MEDICITY joins hands with Nanthancode Jerusalem Mar Thoma
              Parish in Thiruvananthapuram for a free dialysis treatment program
              under the "താങ്ങും തണലും" scheme.</i
            ></b
          >
        </h3>
      </div>
    </div>
    <div class="carousel-item">
      <img
        class="d-block w-100"
        src="assets/img/slider/slider5.jpg"
        alt="First slide"
      />
      <div class="carousel-caption caption">
        <h3 class="slidertext slide-in-left">
          <b
            ><i
              >NIMS Free food item kit for inpatients <br />
              Annapoorna (Associated with Triveni, Govt. of Kerala)</i
            ></b
          >
        </h3>
      </div>
    </div>
    <div class="carousel-item">
      <img
        class="d-block w-100"
        src="assets/img/slider/Homeslider2.jpg"
        alt="assets/img/slider/Homeslider2.jpg"
      />
    </div>
    <div class="carousel-item">
      <img
        class="d-block w-100"
        src="assets/img/slider/Homeslider3.jpg"
        alt="Third slide"
      />
    </div>
    <div class="carousel-item">
      <img
        class="d-block w-100"
        src="assets/img/slider/Homeslider4.jpg"
        alt="Third slide"
      />
    </div>
  </div>
  <a
    class="carousel-control-prev"
    href="#carouselExampleControls"
    role="button"
    data-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next"
    href="#carouselExampleControls"
    role="button"
    data-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
