<div>
  <app-header></app-header>
  <div class="container">
    <div class="row">
      <!-- <div class="elfsight-app-83f33fe2-e721-40a1-8662-290d7a16dd9e"></div> -->
      <!-- <div class="elfsight-app-cdabf56d-779a-4be1-9cb7-dd3a56ec3a0c"></div> -->
      <div class="elfsight-app-da6872e7-bc50-4da1-97d4-49cec9dfd13e"></div>
    </div>
  </div>
<div class="sticky-container">
  <ul class="sticky">
    <div class="plus">
      <img src="assets/img/NIMS-01 (1).png" class="img-fluid" alt="Responsive image">
    </div>
  </ul>  
</div> 
  <router-outlet> </router-outlet>
  <app-footer></app-footer>
</div>
