import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CampusConductedComponent } from './campus-conducted/campus-conducted.component';
import { DreamUsComponent } from './dream-us/dream-us/dream-us.component';
import { AboutUsComponent } from './about-us/about-us/about-us.component';
import { GalleryComponent } from './gallery/gallery/gallery.component';
import { CommonUseModule } from '../common/common-use.module';
import { NgCircleProgressModule } from 'ng-circle-progress';



@NgModule({
  declarations: [
    DashboardComponent,
    CampusConductedComponent,
    DreamUsComponent,
    AboutUsComponent,
    GalleryComponent
  ],
  imports: [
    CommonModule,
    CommonUseModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300
    })
  ],
  exports: [
    DashboardComponent,
    CampusConductedComponent,
    DreamUsComponent,
    AboutUsComponent,
    GalleryComponent
  ]
})
export class DashBoardModule { }
