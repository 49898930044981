import { Component, OnInit } from '@angular/core';
import { Appconstant } from './constant/app.constatnt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'nims-charity';
   ngOnInit():void {
   
   }
}
