import { Component, OnInit } from '@angular/core';
import { Appconstant } from '../../constant/app.constatnt';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  homeButtonLabels: any;
  aboutButtonLables: any;
  servicesButtonLabels: any;
  eventsButtonLabels: any;
  contactButtonLabels: any;
  appConstant: Appconstant = new Appconstant();
  constructor() { }

  ngOnInit(): void {
    this.homeButtonLabels = this.appConstant.CHARITY_BUTTON;
    this.aboutButtonLables = this.appConstant.MEDIA_BUTTON;
    this.servicesButtonLabels = this.appConstant.SERVICES_BUTTON;
    this.eventsButtonLabels = this.appConstant.EVENT_BUTTON;
    this.contactButtonLabels = this.appConstant.CONTACTUS_BUTTON;
  }

}
