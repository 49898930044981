import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonGalleryComponent } from './common-gallery/common-gallery.component';
import { LightboxModule } from 'ngx-lightbox';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { SpectrumSliderComponent } from './spectrum-slider/spectrum-slider.component';
import { HeartSliderComponent } from './heart-slider/heart-slider.component';
import { EducationSliderComponent } from './education-slider/education-slider.component';
import { CivilSliderComponent } from './civil-slider/civil-slider.component';
import { CharityGalleryComponent } from './charity-gallery/charity-gallery.component';


@NgModule({
  declarations: [
    CommonGalleryComponent,
    HomeSliderComponent,
    SpectrumSliderComponent,
    HeartSliderComponent,
    EducationSliderComponent,
    CivilSliderComponent,
    CharityGalleryComponent
  ],
  imports: [
    CommonModule,
    LightboxModule
  ],
  exports: [
   CommonGalleryComponent,
   HomeSliderComponent,
   SpectrumSliderComponent,
   HeartSliderComponent,
   EducationSliderComponent,
   CivilSliderComponent,
   CharityGalleryComponent
  ]
})
export class CommonUseModule { }
