import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './components/dashboard/about-us/about-us/about-us.component';
import { CampusConductedComponent } from './components/dashboard/campus-conducted/campus-conducted.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { DreamUsComponent } from './components/dashboard/dream-us/dream-us/dream-us.component';
import { GalleryComponent } from './components/dashboard/gallery/gallery/gallery.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'cc', component: CampusConductedComponent },
  { path: 'dream', component: DreamUsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'charity',
  loadChildren: () => import('./components/charity/charity.module').then(m => m.CharityModule)
  },
  { path: 'events',
  loadChildren: () => import('./components/events/events.module').then(m => m.EventsModule)
},
  { path: 'contact-us',
  loadChildren: () => import('./components/contact-us/contact-us.module').then(m => m.ContactUsModule)
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
