<!-- <div
  id="carousel-example-2"
  class="carousel slide carousel-fade z-depth-1-half"
  data-ride="carousel"
>
  <div class="carousel-inner" role="listbox">
    <div class="carousel-item active">
      <div class="view">
        <img
          class="d-block w-100"
          src="assets/img/slider/spectrum2.jpg"
          alt="First slide"
        />
        <div class="mask rgba-black-light"></div>
      </div> -->
      <!-- <div class="carousel-caption">
        <p class="centered slidertext">
          <span class="line1">Spectrum</span><br />
          <span class="line2">NOT WITH MONEY</span>
        </p>
      </div> -->
    <!-- </div>
  </div>

  <a
    class="carousel-control-prev"
    href="#carousel-example-2"
    role="button"
    data-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next"
    href="#carousel-example-2"
    role="button"
    data-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div> -->

<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="assets/img/slider/spectrum2.jpg" alt="First slide">
    </div>
    <!-- <div class="carousel-item">
      <img class="d-block w-100" src="assets/img/slider/Homeslider2.jpg" alt="assets/img/slider/Homeslider2.jpg">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="assets/img/slider/Homeslider3.jpg" alt="Third slide">
    </div> -->
  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>