import { Component, Input, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-common-gallery',
  templateUrl: './common-gallery.component.html',
  styleUrls: ['./common-gallery.component.css']
})
export class CommonGalleryComponent implements OnInit {

  @Input() getFilesPath:[];
  dashBoardGallery: any;
  constructor(private _lightbox: Lightbox) { }

  ngOnInit(): void {
    this.dashBoardGallery = this.getFilesPath;
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.dashBoardGallery, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
