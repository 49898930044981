import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DashBoardModule } from "./components/dashboard/dash-board.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { CommonUseModule } from "./components/common/common-use.module";

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DashBoardModule,
    MatMenuModule,
    MatIconModule,
    CommonUseModule,
    HttpClientModule,
  ],
  exports: [MatMenuModule, MatIconModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
